@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./assets/fonts/Satoshi-Regular.woff) format("woff");
}
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./assets/fonts/Satoshi-Bold.woff) format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./assets/fonts/Satoshi-Medium.woff) format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./assets/fonts/Satoshi-Black.woff) format("woff");
  font-weight: 900;
}
@import url("https://fonts.googleapis.com/css2?family=PT+Serif:ital@0;1&display=swap");

$page-padding: 32px;

body {
  font-family: "Satoshi";
}

.App {
  position: relative;

  &-page {
    padding: $page-padding;
    padding-left: calc(248px + $page-padding);
    transition: padding-left 0.5s;
    &.minimized {
      padding-left: calc(80px + $page-padding);
      .loader.full {
        width: calc(100% - 80px);
      }
    }
  }

  .page {
    &-header {
      box-shadow: inset 0px -1px 0px #dbe1e7;
      margin: 0 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-buttons {
      display: flex;
      align-items: center;

      button,
      a {
        margin-left: 16px;
      }
    }

    &-credits-indicator {
      color: #75787e;
      font-size: 0.8rem;
      margin-right: 10px;

      &-error {
        color: #ff0000;
      }
    }

    &-title {
      margin: 0;
      padding: 32px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #0f0f0f;
    }
    &-container {
      padding: 40px 40px;
    }
  }
}

a {
  text-decoration: none;
}
