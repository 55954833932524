@import "../../styles/colors";

.sidebar {
  height: 100vh;
  width: 248px;
  background-color: #E4F1FF;
  box-shadow: 0 0 10px #d8d3d3;
  white-space: nowrap;
  position: absolute;
  transition: width 0.6s ease;
  position: fixed;
  z-index: 10;

  *{
    text-decoration: none;
  }

  .separator {
    background: #363636;
    width: 100%;
    height: 1px;
    opacity: 0;
    transition: opacity 0.6s ease;
  }

  svg {
    flex-shrink: 0;
  }

  &.minimized {
    width: 80px;
    .sidebar-container {
      width: 80px;
      overflow: visible;
    }
    .separator {
      opacity: 1;
    }
    .sidebar-minimize {
      background: #E4F1FF;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      &:hover{
          background: #017CF8;
      }
      svg {
        transform: rotate(180deg);
        stroke: #fff;
       
      }
    }
    .sidebar-options-title {
      opacity: 0;
      visibility: hidden;
      font-size: 0;
      padding: 0;
    }
    .sidebar-logo {
      width: 100%;
      text-align: center;
    }

    .sidebar-options {
      border-bottom-color: #363636;
    }
  }

  &-logo {
    padding: 0 16px;
    padding-bottom: 12px;
  }

  &-container {
    width: 248px;
    min-height: 60%;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    transition: all 600ms ease;
    color: #75787e;
    padding: 15px 0 32px 0;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    -ms-overflow-style: none; // IE 10+
    overflow: -moz-scrollbars-none; // Firefox

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  &-minimize {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background: #E4F1FF;
    &:hover{
      background: $primary;
    }
    svg{
      stroke: #fff;
    }
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -12px;
    top: 40px;
    left: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &-options {
    padding: 8px 16px;
    &-title {
      color: black;
      font-weight: 700;
      font-size: 0.8rem;
      text-transform: uppercase;
      padding: 0 12px 10px;
      transition: all 0.3s ease;
    }
    &.logout {
      margin-top: auto;
    }
  }

  &-option {
    height: 37px;
    width: 100%;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 12px;
    transition: all 0.3s ease-in-out;
    margin-top: 5px;
    svg{
      width:20px;
      height: 20px;
    }
    &.active,
    &:hover {
      background: $primary;
      color: #fff;

      svg > path {
        fill: #fff;
         stroke: #fff;
      }
    }

    > span {
      padding-left: 18px;
    }
  }
}


.sidebar-blessing{
  padding: 0px 16px;
  margin-bottom: 10px;
  color: black;
  font-size: 1rem;
}

.sidebar-tabs-container{
  position: relative;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.sidebar-tabs-container::-webkit-scrollbar {
  width: 4px;
  background: none;
  border-radius: 10px;
  padding-right: 2px;
}

.sidebar-tabs-container::-webkit-scrollbar-thumb {
  width: 4px;
  margin-right: 2px;
  background: #007CF8;
  -webkit-border-radius: 1ex;
  border-radius: 1ex;
}