.login-page {
  display: flex;
  svg.logo{
    position: absolute;
    top:32px;
    left:28px;
  }
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 100%;
    > div {
      min-height: 400px;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #0f0f0f;
      }
    }
  }
}
